@tailwind base;
@tailwind components;
@tailwind utilities;

body
{
  font-family: 'Lato', sans-serif;
}
.hide-scrollbar::-webkit-scrollbar
{
  display: none;
}